<template>
<v-container>
  <navbar title="ورود" route='/'/>
    <v-main class="pt-15">
      <div>
        <error/>
      </div>
      <v-form @submit.prevent="login()" ref="login">
      <v-card class="mx-auto mt-9 card flat-card" color="surface">
        <v-card-text>
          <v-text-field
           label="شماره همراه"
           maxlength="11" type="tell" v-model="infoLogin.username" :rules="[ rules.required, rules.phone, rules.type_number ]"
           required
           prepend-icon="mdi-cellphone"/>
          <v-text-field
          label="رمز عبور" 
          v-model="infoLogin.password"
          :rules="[rules.required, rules.count, rules.password]"
          :type="showPassword ? 'text' : 'password'"
          prepend-icon="mdi-lock-outline"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"/>
            <v-checkbox hide-details v-model="infoLogin.remmber" label="مرا به خاطر بسپار"></v-checkbox>
        </v-card-text>
        <v-card-actions class="d-flex flex-column">
          <v-btn @click="check" :class="{'is-loading': loading}" :disabled="loading" class="button w-100 onPrimaryActions--text primaryActions" type="submit">
            <loading v-if="loading" class="loading"/>ورود
          </v-btn>
          <v-row class="mt-3">
            <v-col>
              <router-link to="/register" class="d-block text-center font info--text text-decoration-none" >میخواهم ثبت نام کنم</router-link>
              <router-link to="/forget_password" class="d-block mt-3 text-center text-decoration-none font info--text">فراموشی رمز عبور</router-link>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-main>
  </v-container>
</template>
<style lang="scss" scoped>
.v-input--selection-controls{
  margin-top: 0;
}
.v-input{
  font-size: 14px;
}

.is-loading {
  color:transparent;
}
.loading{
  margin-inline: auto;
  font-size: 8px;
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
}
</style>
<script>
import navbar from '@/components/navbar/appBar'
import error from '@/components/alerts/error.alert'
import loading from '@/components/tools/loading'
import {login} from './models/auth'
import { mapState } from 'vuex'
import { alertMessage } from '@/assets/messageAlert'
import { toEnglishDigits } from '@/models/changeType'
export default {
  components: {
    error,
    loading,
    navbar
  },
  created () {
    const phone = localStorage.getItem("confirm_login")
    if (phone) {
      this.infoLogin.username = phone
      localStorage.removeItem("confirm_login")
    }
  },
  data: () => ({
    showPassword:false,
    infoLogin: {
      username: '',
      password: ''
    },
    rules: {
      required: null,
      count: value => value.length <= 20 || alertMessage.error['MaxCharacters'],
      password: value => value.length >= 6 || alertMessage.error['min_password'],
      phone: v => {
        const mobile = toEnglishDigits(v)
       return mobile.slice(0,2) == '09' || alertMessage.error['typePhone']},
      type_number: value => {
        const mobile = toEnglishDigits(value)
        const pattern = /^[0-9]+$/
        return pattern.test(mobile) || alertMessage.error['type_number']
      },
    }
  }),
  computed: {
    ...mapState({
      loading: state => state.loadingSubmit
    })
  },
  methods: {
    check () {
      this.rules.required =
       value => !!value || alertMessage.error['require']
    },
    login () {
      const validation = this.$refs.login.validate()
      if (validation == true) {
        login({username: toEnglishDigits(this.infoLogin.username), password: toEnglishDigits(this.infoLogin.password)})
      }
    }
  }
}
</script>
